<template>
  <v-radio :value="ratingModel" class="pa-2" color="#0083B4">
    <template v-slot:label>
      {{ formattedRating }}{{ ratingText }}
      <v-rating
        v-if="ratingView"
        v-model="ratingModel"
        half-increments
        :readonly="true"
        class="d-flex justify-start ml-2"
      >
        <template v-slot:item="props">
          <template v-if="props.isFilled">
            <v-img
              :src="fullStar"
              class="mr-1"
              width="13"
              height="13"
              alt="full star"
            />
          </template>
          <template v-else-if="props.isHalfFilled">
            <v-img
              :src="halfStar"
              class="mr-1"
              width="13"
              height="13"
              alt="half star"
            />
          </template>
          <template v-else>
            <v-img
              :src="emptyStar"
              class="mr-1"
              width="13"
              height="13"
              alt="empty star"
            />
          </template>
        </template>
      </v-rating>
    </template>
  </v-radio>
</template>

<script>
export default {
  name: 'RatingFilterRatingSlot',
  props: {
    ratingModel: {
      type: Number,
    },
    ratingText: {
      type: String,
      default: '+',
    },
    ratingView: {
      type: Boolean,
    },
  },
  data() {
    return {
      fullStar: require('@/assets/full-star.svg'),
      halfStar: require('@/assets/half-star.svg'),
      emptyStar: require('@/assets/empty-star.svg'),
    };
  },
  computed: {
    formattedRating() {
      return this.ratingModel > 1 ? this.ratingModel.toFixed(1) : '';
    },
  },
};
</script>
