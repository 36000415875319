<template>
  <v-container
    fluid
    class="dining-container py-0"
    :class="[
      {
        'px-16 px-sm-10': $vuetify.breakpoint.mdAndUp && !isDiningMapOpen,
      },
      {
        'px-4': $vuetify.breakpoint.smAndDown && isDiningMapOpen,
      },
    ]"
  >
    <vue-headful :title="pageTitle" />

    <v-row no-gutters class="pb-3 pt-8">
      <v-col
        :cols="isDiningMapOpen || $vuetify.breakpoint.mdAndDown ? 12 : 6"
        :class="{ 'mb-2': $vuetify.breakpoint.mdAndDown }"
      >
        <template v-if="isSearching">
          <v-skeleton-loader type="text" :tile="false" width="200" />
        </template>

        <template v-else>
          <h2
            class="result-title"
            :class="{
              'pb-4': $vuetify.breakpoint.smAndDown && isDiningMapOpen,
              'pb-2': isDiningMapOpen,
            }"
          >
            {{ resultsTitle }}
          </h2>
        </template>
      </v-col>

      <v-col :cols="isDiningMapOpen || $vuetify.breakpoint.mdAndDown ? 12 : 6">
        <v-row
          no-gutters
          :justify="
            isDiningMapOpen || $vuetify.breakpoint.mdAndDown ? 'start' : 'end'
          "
        >
          <v-col
            class="pr-2 pl-0"
            cols="auto"
            :class="{
              'mb-2': isDiningMapOpen || $vuetify.breakpoint.mdAndDown,
            }"
          >
            <RatingFilter
              :preSelectedRatingFilter="selectedRating.min"
              @submit="getRating"
            />
          </v-col>

          <v-col
            class="pr-2"
            cols="auto"
            :class="{
              'mb-2': isDiningMapOpen || $vuetify.breakpoint.mdAndDown,
            }"
          >
            <PriceTierFilter
              :preSelectedPriceFilter="selectedPriceScale"
              @submit="getPrices"
            />
          </v-col>
          <v-col
            cols="auto"
            :class="{
              'mb-2': isDiningMapOpen || $vuetify.breakpoint.mdAndDown,
            }"
          >
            <DistanceFilter
              :preSelectedDistanceFilter="selectedRadius.kilometers"
              @submit="getDistance"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <template v-if="!isSearching && !hasDiningSearch">
      <ResultsNotFound />
    </template>

    <template v-else>
      <template v-if="isSearching">
        <v-row no-gutters>
          <v-col cols="12">
            <v-row
              v-for="rowIndex in 4"
              :key="rowIndex + 10"
              justify="space-between"
            >
              <v-col
                v-for="colIndex in 12 / resultsColSize"
                :key="colIndex + 20"
                :cols="resultsColSize"
              >
                <v-skeleton-loader type="card" :loading="true" :tile="false" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </template>

      <template v-if="hasDiningSearch">
        <v-row justify="start">
          <v-col
            :cols="resultsColSize"
            v-for="(item, index) in diningSearch.items"
            data-cy="place-item mb-6"
            @click="pushDiningItemClickAnalytics(item)"
            :key="index"
          >
            <router-link
              class="primary--text font-weight-bold"
              :to="{
                name: 'dining-details',
                params: { id: item.id },
                query: { lng: lng, lat: lat },
              }"
            >
              <DiningItemComponent
                :title="item.title"
                :thumbnail="item.thumbnail"
                :rating="item.rating"
                :priceScale="item.priceScale"
                :aspectRatio="1.5"
              />
            </router-link>
          </v-col>
        </v-row>
      </template>

      <template v-if="hasDiningSearch && diningSearch.isLoading">
        <v-row>
          <v-col cols="12">
            <v-row no-gutters class="pt-6">
              <v-col class="d-flex justify-center pt-12">
                <h2 class="title text-light-gray-color">
                  {{ $t('no_results_found') }}
                </h2>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </template>

      <template v-if="isSearching || isPaginatingSearching">
        <v-row no-gutters>
          <v-col cols="12">
            <v-row
              v-for="rowIndex in 2"
              :key="rowIndex + 10"
              justify="space-between"
            >
              <v-col
                v-for="colIndex in 12 / resultsColSize"
                :key="colIndex + 20"
                :cols="resultsColSize"
              >
                <v-skeleton-loader type="card" :loading="true" :tile="false" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </template>

      <template v-if="canShowLoadMoreResultsButton">
        <v-row class="pt-6" justify="center">
          <v-col cols="auto">
            <v-btn
              depressed
              large
              dense
              class="load-more-button primary"
              text
              @click="
                updateSearchContent(diningSearch.items.length, resultsPageSize)
              "
            >
              <template v-if="$vuetify.breakpoint.mdAndDown">
                {{ $t('dining.view_more_restaurants') }}
              </template>
              <template v-else>
                {{ $t('view_more') }}
              </template>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </template>
  </v-container>
</template>

<script>
import vueHeadful from 'vue-headful';
import RatingFilter from '@/modules/common/filters/RatingFilter';
import DistanceFilter from '@/modules/common/filters/DistanceFilter';
import PriceTierFilter from '@/modules/common/filters/PriceTierFilter';
import DiningItemComponent from './features/Search/DiningItemComponent';
import DiningMixins from '@/modules/product/dining/mixins/DiningMixins';
import ResultsNotFound from '@/modules/product/dining/ResultsNotFound';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';

export default {
  name: 'DiningResultsPage',
  mixins: [DiningMixins, GoogleTagManagerMixins],
  components: {
    DiningItemComponent,
    vueHeadful,
    ResultsNotFound,
    RatingFilter,
    DistanceFilter,
    PriceTierFilter,
  },

  props: {
    city: String,
    searchQuery: String,
    lng: [Number, String],
    lat: [Number, String],
    minPriceScale: [Number, String],
    maxPriceScale: [Number, String],
    minRating: [Number, String],
    radius: [Number, String],
  },

  data() {
    return {
      isDiningMapOpen: false,
    };
  },

  created() {
    if (!this.hasPreviousSearchResults) {
      this.updateSearchContent();
    }
  },

  beforeDestroy() {
    this.clearDining();
  },

  computed: {
    resultsTitle() {
      let total = '';
      if (this.diningSearch.total >= 100) {
        total = '100+';
      } else {
        total = this.diningSearch.total;
      }

      let title = this.$t('dining.results_title', { total: total });
      let city = null;
      if (!!this.diningSearch.city) {
        city = this.diningSearch.city;
      } else if (!!this.city) {
        city = this.city;
      }

      if (city) {
        title = this.$t('dining.top_results', { city: city });
      }
      return title;
    },

    matchedSearchResultsParams() {
      const { city, lng, lat } = this.diningSearch;
      return city === this.city && lng === this.lng && lat === this.lat;
    },

    hasPreviousSearchResults() {
      return this.hasDiningSearch && this.matchedSearchResultsParams;
    },

    resultsColSize() {
      let colSize = 4;
      if (this.$vuetify.breakpoint.smOnly) {
        colSize = 12;
      } else if (this.isDiningMapOpen) {
        colSize = 6;
      } else if (this.$vuetify.breakpoint.mdOnly) {
        colSize = 12;
      } else if (this.$vuetify.breakpoint.lgOnly) {
        colSize = 4;
      } else if (this.$vuetify.breakpoint.xlOnly) {
        colSize = 3;
      } else if (this.$vuetify.breakpoint.xsOnly) {
        colSize = 12;
      }
      return colSize;
    },

    resultsPageSize() {
      let pageSize = 15;
      if (this.$vuetify.breakpoint.smOnly) {
        pageSize = 12;
      } else if (this.$vuetify.breakpoint.mdOnly) {
        pageSize = 15;
      } else if (this.$vuetify.breakpoint.lgOnly) {
        pageSize = 20;
      } else if (this.$vuetify.breakpoint.xlOnly) {
        pageSize = 20;
      } else if (this.$vuetify.breakpoint.xsOnly) {
        pageSize = 12;
      }
      return pageSize;
    },

    isSearching() {
      return this.diningSearch.isLoading;
    },

    isPaginatingSearching() {
      return this.hasDiningSearch && this.diningSearch.isLoading;
    },

    canLoadMoreResults() {
      return this.diningSearch.total > this.diningSearch.items.length;
    },

    canShowLoadMoreResultsButton() {
      return !this.isSearching && this.canLoadMoreResults;
    },

    partnerName() {
      return this.$store.state.configs && this.$store.state.configs.title
        ? this.$store.state.configs.title
        : '';
    },

    pageTitle() {
      if (this.hasDiningSearch) {
        return `Dining | ${this.partnerName} | Guestlogix`;
      } else {
        return '';
      }
    },

    selectedRating() {
      return { min: this.minRating || '' };
    },

    selectedPriceScale() {
      return {
        min: this.minPriceScale || '',
        max: this.maxPriceScale || '',
      };
    },

    selectedRadius() {
      return {
        meters: this.radius * 1000 || '',
        kilometers: this.radius || '',
      };
    },

    searchQueryList() {
      if (this.searchQuery) return this.searchQuery.split(',');
      else return [];
    },
  },

  watch: {
    city(oldVal, newVal) {
      if (newVal !== oldVal) {
        this.updateSearchContent();
      }
    },

    selectedRating: {
      deep: true,
      handler() {
        this.updateSearchContent(0, this.resultsPageSize);
      },
    },

    selectedPriceScale: {
      deep: true,
      handler() {
        this.updateSearchContent(0, this.resultsPageSize);
      },
    },

    selectedRadius() {
      this.updateSearchContent(0, this.resultsPageSize);
    },

    searchQuery(oldVal, newVal) {
      if (newVal !== oldVal) {
        this.updateSearchContent();
      }
    },

    hasDiningSearch(val) {
      if (!val) {
        this.isDiningMapOpen = false;
      }
    },

    $route: {
      immediate: true,
      handler(val) {
        if (!!val && val.name == 'dining-map' && this.hasDiningSearch) {
          this.isDiningMapOpen = true;
        }
        if (val && val.name == 'dining-results') {
          this.isDiningMapOpen = false;
        }
      },
    },
  },

  methods: {
    updateSearchContent(skip, take) {
      this.searchDining({
        city: this.city,
        lng: this.lng,
        lat: this.lat,
        tags: this.searchQueryList,
        minRating: this.selectedRating.min,
        minPriceScale: this.selectedPriceScale.min,
        maxPriceScale: this.selectedPriceScale.max,
        radius: this.selectedRadius.meters,
        skip: skip,
        take: take,
      });
    },

    getPrices(prices) {
      this.selectedPriceScale.min = prices[0];
      this.selectedPriceScale.max = prices[1];
    },

    getRating(rating) {
      this.selectedRating.min = rating;
    },

    getDistance(distance) {
      this.selectedRadius.meters = distance * 1000;
      this.selectedRadius.kilometers = distance;
    },
  },
};
</script>

<style scoped lang="scss">
.dining-container {
  max-width: 1208px;
}
.result-title {
  @include font-size(20, 150);
  color: #05090f;
}

a:link,
a:visited {
  text-decoration: none;
}

.load-more-button {
  @include font-size(14, 150, 500);

  @media (min-width: map-get($grid-breakpoints, md)) {
    @include font-size(20, 150);
  }
}
</style>
