<template>
  <ErrorPage>
    <template v-slot:header>
      <span class="pnf-image"></span>
      <h2 class="pnf-title">{{ $t('no_results_found') }}</h2>
      <p class="pnf-text">
        {{ $t('dining.adjust_search') }}
      </p>
    </template>
    <template v-slot:footer>
      <span></span>
    </template>
  </ErrorPage>
</template>

<script>
import ErrorPage from '@/modules/exception/ErrorPage';

export default {
  name: 'ResultsNotFound',
  components: { ErrorPage },
};
</script>

<style scoped lang="scss">
/* pnf - Product Not Found */
.pnf-title {
  color: #000;
  margin-bottom: 16px;
  @include font-size(18, 150, 600);
}

.pnf-text {
  @include font-size(16, 150);
  color: #000;
}

.pnf-image {
  background: url(/svg/EmptyBox.svg);
  width: 200px;
  height: 165.93px;
  display: flex;
  margin: auto;
}
</style>
