<template>
  <PopoverModal
    :selected="isSelected"
    :title="$t('common.rating')"
    @submit="onSubmit"
    @clear="onClear"
    :triggerText="displayedSelectedRatings"
    :triggerIcon="triggerIcon"
    :closeOnClear="true"
  >
    <v-container class="rating-filter py-0">
      <v-radio-group v-model="radioGroup">
        <RatingFilterRatingSlot
          :ratingModel="rating5"
          :ratingView="false"
          :ratingText="$t('common.any_rating')"
        />
        <RatingFilterRatingSlot :ratingModel="rating4" :ratingView="true" />
        <RatingFilterRatingSlot :ratingModel="rating3" :ratingView="true" />
        <RatingFilterRatingSlot :ratingModel="rating2" :ratingView="true" />
        <RatingFilterRatingSlot :ratingModel="rating1" :ratingView="true" />
      </v-radio-group>
    </v-container>
  </PopoverModal>
</template>

<script>
import PopoverModal from '@/core/components/PopoverModal';
import RatingFilterRatingSlot from './RatingFilterRatingSlot';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';

export default {
  name: 'RatingFilter',
  components: { PopoverModal, RatingFilterRatingSlot },
  mixins: [GoogleTagManagerMixins],
  props: {
    preSelectedRatingFilter: [Number, String],
  },
  data() {
    return {
      radioGroup: null,
      rating1: 4.5,
      rating2: 4,
      rating3: 3.5,
      rating4: 3,
      rating5: 1,
      isSelected: false,
    };
  },
  watch: {
    preSelectedRatingFilter: {
      deep: true,
      handler() {
        this.setDefaultSelection();
      },
    },
  },
  created() {
    this.setDefaultSelection();
  },
  computed: {
    displayedSelectedRatings() {
      if (!this.isSelected) {
        return this.$t('common.rating');
      } else {
        const rating = parseFloat(
          this.preSelectedRatingFilter || this.radioGroup
        ).toFixed(1);
        return rating > 1 ? `${rating}+` : this.$t('common.any_rating');
      }
    },
    triggerIcon() {
      return (this.preSelectedRatingFilter || this.radioGroup) > 1
        ? require('@/assets/full-star.svg')
        : '';
    },
  },
  methods: {
    setDefaultSelection() {
      if (this.preSelectedRatingFilter) {
        this.isSelected = true;
      } else {
        this.isSelected = false;
      }

      let radioGroupTmp = this.preSelectedRatingFilter || 1;
      if (radioGroupTmp) {
        this.radioGroup = parseFloat(radioGroupTmp);
      } else {
        this.radioGroup = null;
      }
    },
    onSubmit() {
      this.pushFilterAnalytics(
        this.$route.path,
        'rating',
        this.displayedSelectedRatings
      );
      this.$emit('submit', this.radioGroup);
      this.updateRatingQuery();
    },
    onClear() {
      this.radioGroup = 1;
      this.$emit('submit', this.radioGroup);
      this.removeQuery();
    },
    removeQuery() {
      let query = Object.assign({}, this.$route.query);
      delete query['min-rating'];
      this.$router.replace({ query }).catch((err) => {});
    },
    updateRatingQuery() {
      const routeConfig = {
        params: { ...this.$route.params },
        query: {
          ...this.$route.query,
        },
      };
      routeConfig.query['min-rating'] = this.radioGroup;
      this.$router.push(routeConfig).catch((err) => {});
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .search-input {
    border-radius: 0px;
  }
}

.rating-filter {
  .v-rating {
    margin-right: 10px;

    .v-icon {
      padding: 0;

      &:before {
        height: 13px;
        width: 13px;
        @include font-size(16, 70);
        color: #ffe500;
      }

      &:not(:last-child) {
        &:before {
          margin-right: 3px;
        }
      }
    }
  }
}
.v-application--is-ltr .v-input--selection-controls__input {
  margin: 0;
}
</style>
