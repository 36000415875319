<template>
  <PopoverModal
    :selected="isSelected"
    :triggerText="displayedSelectedDistance"
    :title="$t('common.distance')"
    @submit="onSubmit"
    @clear="onClear"
    :closeOnClear="true"
  >
    <v-container class="distance-filter">
      <v-row class="py-3 px-5" no-gutters>
        <v-col cols="12">
          <p class="distance-text mb-0">{{ titleQuestion }}</p>
        </v-col>
      </v-row>
      <v-row class="py-3 px-5" no-gutters>
        <v-col cols="12">
          <v-slider
            ref="slider"
            v-model="selectedIndex"
            :tick-labels="ticksLabels"
            :max="5"
            color="primary"
            track-color="#cfd5e0"
            ticks="always"
            tick-size="5"
          />
        </v-col>
      </v-row>
    </v-container>
  </PopoverModal>
</template>

<script>
import PopoverModal from '@/core/components/PopoverModal';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';

export default {
  name: 'DistanceFilter',
  components: { PopoverModal },
  mixins: [GoogleTagManagerMixins],
  props: {
    titleQuestion: {
      type: String,
      default: function() {
        return this.$t('common.find_restaurants_withen');
      },
    },
    preSelectedDistanceFilter: [Number, String],
  },

  data() {
    return {
      selectedIndex: null,
      ticksLabels: ['0', '1', '2', '4', '10', '50km'],
      ticksPoint: [0, 1, 2, 4, 10, 50],
      isSelected: false,
    };
  },

  computed: {
    displayedSelectedDistance() {
      if (!this.isSelected) {
        return this.$t('common.distance');
      } else {
        if (this.selectedIndex == 5) {
          return this.$t('common.distance_within', {
            range: this.ticksLabels[this.selectedIndex],
          });
        } else {
          return this.$t('common.distance_within_km', {
            range: this.ticksLabels[this.selectedIndex],
          });
        }
      }
    },
  },

  created() {
    this.setDefaultSelection();
  },

  watch: {
    preSelectedDistanceFilter: {
      deep: true,
      handler() {
        this.setDefaultSelection();
      },
    },
  },

  methods: {
    setDefaultSelection() {
      if (this.preSelectedDistanceFilter) {
        this.isSelected = true;
      } else {
        this.isSelected = false;
      }

      let selectedIndexTmp = this.preSelectedDistanceFilter || 10;
      if (selectedIndexTmp) {
        this.getDistanceIndex(selectedIndexTmp);
      } else {
        this.selectedIndex = null;
      }
    },

    getDistanceIndex(val) {
      let tmpNumber = val;
      let tmpConvertedNumber = Math.round(parseFloat(tmpNumber).toFixed(2));
      let clossestValue = this.ticksPoint.reduce((a, b) => {
        return Math.abs(b - tmpConvertedNumber) <
          Math.abs(a - tmpConvertedNumber)
          ? b
          : a;
      });
      this.selectedIndex = this.ticksPoint.findIndex(
        (rank) => rank === clossestValue
      );
    },

    onSubmit() {
      this.pushFilterAnalytics(
        this.$route.path,
        'distance',
        this.displayedSelectedDistance
      );

      this.$emit('submit', this.ticksPoint[this.selectedIndex]);
      this.updateDistanceQuery();
    },

    onClear() {
      this.selectedIndex = 4;
      this.$emit('submit', this.ticksPoint[this.selectedIndex]);
      this.removeQuery();
    },

    removeQuery() {
      let query = Object.assign({}, this.$route.query);
      delete query['radius'];
      this.$router.replace({ query }).catch((err) => {});
    },

    updateDistanceQuery() {
      const routeConfig = {
        params: { ...this.$route.params },
        query: {
          ...this.$route.query,
        },
      };
      routeConfig.query['radius'] = this.ticksPoint[this.selectedIndex];
      this.$router.push(routeConfig).catch((err) => {});
    },
  },
};
</script>

<style lang="scss">
::v-deep .search-input {
  border-radius: 0px;
}

.distance-filter {
  .container {
    min-width: 400px;
  }

  .v-sheet {
    padding: 10px;
  }

  .v-slider {
    margin: 0;

    &__tick {
      background-color: #858f9d;

      &-label {
        @include font-size(14, 150, 600);
        color: #05090f;
        top: 15px !important;
      }

      &:not(:first-child) {
        .v-slider__tick-label {
          padding-left: 5px;
        }
      }

      &:last-child {
        .v-slider__tick-label {
          left: 8px;
        }
      }
    }
  }
}
</style>
