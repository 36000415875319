<template>
  <PopoverModal
    :selected="isSelected"
    :triggerText="displayedSelectedPriceTier"
    :title="$t('common.price')"
    @submit="onSubmit"
    @clear="onClear"
    :closeOnClear="true"
  >
    <v-container class="pricetier-filter">
      <v-row class="py-3 px-5">
        <v-range-slider
          v-model="range"
          :value="range"
          :tick-labels="ticksLabels"
          :min="minModel"
          :max="maxModel"
          color="primary"
          :track-color="'#cfd5e0'"
          ticks="always"
          tick-size="4"
        />
      </v-row>
    </v-container>
  </PopoverModal>
</template>

<script>
import PopoverModal from '@/core/components/PopoverModal';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';

export default {
  name: 'PriceTierFilter',
  components: { PopoverModal },
  mixins: [GoogleTagManagerMixins],
  props: {
    preSelectedPriceFilter: {
      type: Object,
      default: () => ({ min: '', max: '' }),
    },
  },
  data() {
    return {
      minModel: 1,
      maxModel: 4,
      range: [],
      ticksLabels: ['$', '$$', '$$$', '$$$$'],
      isSelected: false,
    };
  },
  computed: {
    displayedSelectedPriceTier() {
      if (!this.isSelected) {
        return this.$t('common.price');
      } else {
        return `${this.ticksLabels[this.range[0] - 1]} - ${
          this.ticksLabels[this.range[1] - 1]
        }`;
      }
    },
  },
  created() {
    this.setDefaultSelection();
  },
  methods: {
    onSubmit() {
      this.pushFilterAnalytics(this.$route.path, 'price', this.range);
      this.$emit('submit', this.range);
      this.updatePriceQuery();
    },
    onClear() {
      this.minModel = 1;
      this.maxModel = 4;
      this.range = [];
      this.$emit('submit', this.range);
      this.removeQuery();
    },
    setDefaultSelection() {
      if (this.preSelectedPriceFilter.min || this.preSelectedPriceFilter.max) {
        this.isSelected = true;
      } else {
        this.isSelected = false;
      }

      let minPrice = this.preSelectedPriceFilter.min || 1;
      let maxPrice = this.preSelectedPriceFilter.max || 4;

      if (minPrice || maxPrice) {
        this.range = [minPrice, maxPrice];
      } else {
        this.range = [this.minModel, this.maxModel];
      }
    },
    removeQuery() {
      let query = Object.assign({}, this.$route.query);
      delete query['min-price-scale'];
      delete query['max-price-scale'];
      this.$router.replace({ query }).catch((err) => {});
    },
    updatePriceQuery() {
      const routeConfig = {
        params: { ...this.$route.params },
        query: {
          ...this.$route.query,
        },
      };
      routeConfig.query['min-price-scale'] = this.range[0];
      routeConfig.query['max-price-scale'] = this.range[1];
      this.$router.push(routeConfig).catch((err) => {});
    },
  },
  watch: {
    preSelectedPriceFilter: {
      deep: true,
      handler() {
        this.setDefaultSelection();
      },
    },
  },
};
</script>
<style lang="scss">
::v-deep .search-input {
  border-radius: 0px;
}
.pricetier-filter {
  .v-slider__tick-label {
    font-weight: 600;
    left: 3px;
  }
  .v-slider__tick {
    &:first-child {
      .v-slider__tick-label {
        left: -3px;
      }
    }
    &:last-child {
      .v-slider__tick-label {
        left: 8px;
      }
    }
  }
}
</style>
